import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image";
import TopBtn from '../components/topBtn';

import "../assets/stylesheets/services_page.scss"

import LayoutExternal from '../components/layoutExternal'

export const workQuery = graphql`query WorksQuery {
  site {
    siteMetadata {
      title
      description
    }
  }
  allMarkdownRemark(filter: {frontmatter: {type: {eq: "work"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          type
          title
          order
          description
          imahover {
            childImageSharp {
             gatsbyImageData(layout: CONSTRAINED, quality: 20, placeholder: BLURRED)
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 20, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}
`;

  const ProcedureImage = styled(GatsbyImage)`
  width: 350px !important;
  height:  490px !important;
  display: inline-block !important;
  position: absolute !important;
  `
  const ProcedureImageHover = styled(GatsbyImage)`
  width: 350px !important;
  height:  490px !important;
  display: none!important;
  position: absolute !important;
  z-index: 1 !important;
  `
const HoverContainer = styled.div`
margin-top: 1rem;
margin-left: auto;
margin-right: auto;
position: relative;	
width: 350px;
height:  490px;
&:hover .procedureNoColor{
  display: inline-block !important;
}
`
const TextResp = styled.div`
  width: 75%;
  position: absolute;
  bottom: 25%;
  z-index: 3;
}
`
let Work = ({ image, imageHover, description }) => (
  <Col lg="4">
      <HoverContainer>
      <ProcedureImage image ={image} />
      <ProcedureImageHover loading="eager" image ={imageHover} className="procedureNoColor" />
      <TextResp className="centered">{description}</TextResp>
      </HoverContainer>
  </Col>
)


function sortList(list) {
  return list.sort((a, b) =>  a.node.frontmatter.order - b.node.frontmatter.order);
}


export default function Works(props) {  
  const worksList = props.data.allMarkdownRemark.edges;

  sortList(worksList);
  return (
    <LayoutExternal>
        <Container className="servives-page">
          <Row>
          {worksList.map(({ node }, i) => (
                <Work key={i} description={node.frontmatter.description} image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                imageHover={node.frontmatter.imahover.childImageSharp.gatsbyImageData}
                />
            ))}
          </Row>
        </Container>
        <TopBtn showBelow={250} />
    </LayoutExternal>
  );
}